.min-width-degivrare {
  min-width: 240px !important;
  max-width: 100% !important;
  min-height: 142px !important;
}

.degivrare-poza {
  width: 100%;
  max-width: 1008px;
  max-height: 477.5px;
  margin: auto;
}

.poza {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0px 5px 15px 0 rgba(0, 0, 0, 0.19);
  margin-top: 8px;
}

.text-alcatuire {
  text-indent: 0 !important;
}
