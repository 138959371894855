.properties-figo {
  text-align: left !important;
}

.figo-culori {
  width: 100%;
  max-width: 600px;
}

.paletar {
  min-width: 240px;
  width: 100%;
}

.figo-image {
  width: 100%;
  min-width: 240px;
}

.col-figo {
  min-width: 240px;
}

.figo-paletar {
  margin: auto;
  max-width: 550px;
  max-height: 320px;

  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-top: 14px;
}
