.ferestre-imagine {
  max-width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.col-velux {
  margin-top: 8px;
}

.ferestre-imagine-multiple {
  max-width: 100%;
}

.max-width {
  max-width: 80% !important;
  margin: auto;
  margin-top: 8px;
}

.min-width {
  min-width: 266px !important;
}

@media screen and (max-width: 1769px) {
  .evacuare-min-height {
    min-height: 91.5px !important;
  }
}

@media screen and (max-width: 1271px) and (min-width: 1201px) {
  .evacuare-min-height {
    min-height: 109.5px !important;
  }
}
