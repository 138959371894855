.max-width {
  max-width: 600px !important;
  margin: auto;
}

.rulouri-imagine {
  max-width: 100%;
}

.rulouri-culoare {
  width: 100%;
  height: 100%;
  max-width: 75px;
  min-width: 25px;
  max-height: 75px;
  min-height: 25px;
  margin-left: 4px;
  cursor: pointer;
}

.rulouri-culori {
  height: 100px;
  margin: auto;
  text-align: center !important;
}
.rulouri-culori img {
  margin: auto;
}

.roleta-header {
  height: 65px;
}

.min-width-rulouri {
  min-width: 286px;
  max-width: 431px !important;
}
.min-width-rulouri-decorative {
  min-width: 286px;
  max-width: 500px !important;
}

.rulouri-card {
  background: rgb(171, 177, 175);
  background: linear-gradient(
    90deg,
    rgba(171, 177, 175, 1) 0%,
    rgba(171, 177, 175, 1) 19%,
    rgba(143, 156, 154, 1) 67%,
    rgba(171, 177, 175, 1) 100%
  );
}
