.lindab-culori {
  min-width: 240px;
  max-width: 100%;
}

.lindab-image {
  width: 80%;
  min-width: 240px;
  min-height: 240px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 2px 8px 20px 0 rgba(0, 0, 0, 0.19);
}

.lindab-rectangle {
  max-width: 840px;
  margin: auto;
  height: 70px;
}

.paletar {
  margin: auto;
  max-width: 550px;
  max-height: 320px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 2px 8px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-top: 14px;
}

.alb-010 {
  background-color: #f4f4f4;
}

.negru-015 {
  background-color: #020001;
}

.gri-deschis-022 {
  background-color: #dcdacd;
}

.grafit-044 {
  background-color: #898483;
}

.silver-metalic-045 {
  background-color: #a6a6a8;
}

.gri-inchis-087 {
  background-color: #44504e;
}

.maro-434 {
  background-color: #3d2115;
}

.gri-neutru-461 {
  background-color: #bbbcae;
}

.albastru-deschis-502 {
  background-color: #6f9ea6;
}

.caramiziu-742 {
  background-color: #9d3b1e;
}

.rosu-inchis-758 {
  background-color: #802a1d;
}

.aramiu-778 {
  background-color: #946e4a;
}

.verde-padure-830 {
  background-color: #30574c;
}

.verde-inchis-874 {
  background-color: #536e3b;
}

.verde-deschis-975 {
  background-color: #5b9370;
}

.text-description {
  margin: auto;
  min-width: 240px;
  text-align: justify !important;
  text-indent: 16px;
  padding: 0px;
}

.lindab-metalica-image {
  width: 100%;
  max-width: 400px;
  min-width: 240px;
}

.bej-113 {
  background-color: #f0edda;
}

.galben-deschis-152 {
  background-color: #e5bf5e;
}

.rosu-foc-412 {
  background-color: #c4071b;
}

.albastru-neutru-542 {
  background-color: #004787;
}

.albastru-inchis-524 {
  background-color: #004459;
}

.galben-inchis-116 {
  background-color: #c19558;
}

.lindab-roca-image {
  max-width: 100%;
  min-width: 240px;
}

.lindab-roca-culori {
  min-width: 240px;
  max-width: 100%;
}
