.braas-images {
  width: 100%;
  min-width: 240px !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.padding-top {
  padding-top: 16px;
}

.antracit-briliant {
  background-color: #252527;
}

.text-antracit-briliant {
  color: #252527;
}

.castaniu {
  background-color: #8a523b;
}

.text-castaniu {
  color: #8a523b;
}

.teak {
  background-color: #301b15;
}

.text-teak {
  color: #301b15;
}

.braas-imagine {
  margin: auto;
  width: 250px;
  height: 195px;
}

.text-culoare-braas {
  padding-top: 0px;
}

.braas-col {
  min-width: 240px !important;
}
