.map {
  width: 100%;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
}
input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: #45a049;
}

.modal {
  overflow: hidden !important;
}

.modal-body {
  overflow: auto !important;
}

.modal-dialog {
  min-width: 80%;
  margin: auto !important;
  margin-top: 14px !important;
  margin-bottom: 14px !important;
  overflow: hidden !important;
}

.modal-dialog h1 {
  font-size: 2rem;
  margin: auto;
  text-align: center;
}

.termeni {
  cursor: pointer;
}

.form-check {
  border-bottom: 1px solid transparent;
}

.outline-red {
  transition: 0.2s;
  border-bottom: 1px solid red;
}
