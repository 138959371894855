.stabicor-image {
  max-width: 100%;
}

.max-width-stabicor {
  max-width: 480px !important;
}

.culoare-stabicor {
  width: 100px;
  height: 100px;
  margin-left: 4px;
  cursor: pointer;
  margin-top: 4px;
}

.culori-text {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  margin-top: 0;
}

.culori-text-culoare {
  display: inline;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.max-width-348 {
  max-width: 348px !important;
}

.widgets {
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.widgets img {
  width: 25%;
  max-width: 100px;
}
