.footer-section {
  margin-top: 12px !important;
  min-height: 128px;
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #363435;
  font-weight: 500;
  font-size: 12px;
  bottom: 0px;
  background-image: url("/assets/images/body-bg-bottom2.png");
  background-position: center bottom;
}

span {
  float: left;
  width: 50%;
}

.facebook-svg {
  width:35px;
  height:35px;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.flex-container {
  position: absolute;
  bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
}

.flex-container div:last-child {
  margin-left: auto;
}

.copyright {
  padding-top: 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.social-icons {
  font-size: 32px;
}

.margin-left {
  margin-left: auto !important;
}

.facebook-icon {
  color: #fff;
}

.facebook-icon:hover {
  color: #fff !important;
  background-color: transparent !important;
}

.marquee {
  max-width: 1903px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  padding-top: 8px;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  cursor: pointer;
  height: 52px;
}

.marquee span:hover {
  animation-play-state: paused;
}

.marquee img:not(:first-child) {
  margin-left: 16px;
}

.marquee img:hover {
  transition: 0.2s;
  transform: scale(1.1);
}

/* Make it a marquee */
@media screen and (max-width: 347px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-600%, 0);
    }
  }
}
@media screen and (max-width: 685px) and (min-width: 347px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-500%, 0);
    }
  }
}
@media screen and (max-width: 920px) and (min-width: 685px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-350%, 0);
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 920px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-275%, 0);
    }
  }
}
@media screen and (max-width: 1600px) and (min-width: 1200px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-232%, 0);
    }
  }
}
@media screen and (min-width: 1600px) {
  /* Make it move */
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-200%, 0);
    }
  }
}
