.tag {
  background-color: #b13336;
  margin-left: -1px;
  width: 150px;
  padding-top: 14px;
  height: 50px;
  color: #fefefe;
  text-align: center !important;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.arrow-right {
  width: 0;
  height: 0;
  margin-left: -1px;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 28px solid #b13336;
}

.no-padding {
  padding-top: 2px !important;
}

.arrow_box {
  background-color: #b13336;
  margin-left: -1px;
  width: 150px;
  padding-top: 14px;
  height: 50px;
  color: #fefefe;
  text-align: center !important;
  text-transform: uppercase;
  font-weight: 650;
  letter-spacing: 0.6px;

  position: relative;
}
.arrow_box:after,
.arrow_box:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #b13336;
  border-width: 25px;
  margin-top: -25px;
}

.portfolio {
  font-size: 11px !important;
}
