.list-group-item {
  cursor: pointer;
  border: 0 !important;
}

.list-group-item:hover {
}

.list-link:hover {
  background-color: rgba(177, 51, 54, 0.02);
}

.card-title {
  text-transform: uppercase;
  font-size: 100%;
  margin-bottom: 0 !important;
}

.secondary-list {
  font-size: 14px;
  -webkit-animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-3-11 11:43:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.category-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.card-body {
  text-align: center;
  color: #fefefe;
  margin-bottom: -1px !important;
  margin-top: -2px !important;
  background: rgb(177, 51, 54);
  background: radial-gradient(circle, rgba(177, 51, 54, 1) 0%, rgba(198, 75, 78, 1) 94%, rgba(209, 87, 90, 1) 100%);
}

.card {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 8px;
  min-width: 240px !important;
  max-width: 100% !important;
}

.min-height {
  min-height: 222.5px;
}

.card:hover {
  -moz-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  -o-transform: translate(0px, -4px);
  -webkit-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2), 0 7px 10px 0 rgba(0, 0, 0, 0.19);
}

.empty-card {
  position: relative;
  flex-direction: column;
  min-width: 240px !important;
  max-width: 100% !important;
}
