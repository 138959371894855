.square {
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: inline-block;
  margin-top: 4px;
  margin-right: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.squares {
  text-align: left !important;
  padding-left: 16px;
  padding-right: 16px;
}
.alb-1025 {
  background-color: #eeeeee;
}

.bej-deschis-1085 {
  background-color: #f3e6d3;
}

.albastru-pal-4555 {
  background-color: #e0e6e4;
}

.model-esential-4558 {
  background-color: #f9f5e9;
}

.maro-inchis-4559 {
  background-color: #59393c;
}

.rosu-inchis-4560 {
  background-color: #821a3b;
}

.purpuriu-inchis-4561 {
  background-color: #5c2741;
}

.model-intunecat-4562 {
  background-color: #070807;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-intunecat.png");
}

.curry-4563 {
  background-color: #e8b252;
}

.bej-4556 {
  background-color: #d5c3ab;
}

.portocaliu-4564 {
  background-color: #d46740;
}

.roz-pal-4565 {
  background-color: #ac8e98;
}

.verde-masliniu-4567 {
  background-color: #6f7849;
}

.model-vegetal-4568 {
  background-color: #fd8f44;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-vegetal.png");
}

.verde-pal-4569 {
  background-color: #ccee7d;
}

.galben-aprins-4570 {
  background-color: #ffea01;
}

.albastru-deschis-4571 {
  background-color: #8ba9b1;
}

.rosu-aprins-4572 {
  background-color: #e64044;
}

.model-grafic-4573 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-grafic.png");
}

.albastru-2055 {
  background-color: #2f406c;
}

.albastru-inchis-1100 {
  background-color: #2c324c;
}

.gri-deschis-1705 {
  background-color: #bac1b9;
}

.gri-0705 {
  background-color: #918a80;
}

.negru-3009 {
  background-color: #3e3e3e;
}

.model-4650 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4650.png");
}

.model-4651 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4651.png");
}
.model-4652 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4652.png");
}

.model-4653 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4653.png");
}

.model-4654 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4654.png");
}

.model-4655 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4655.png");
}

.model-4656 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4656.png");
}

.model-4657 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4657.png");
}

.model-4658 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4658.png");
}
.model-4659 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4659.png");
}

.model-4660 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4660.png");
}
.model-4661 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4661.png");
}
.model-4662 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4662.png");
}
.model-4663 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4663.png");
}
.model-4664 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4664.png");
}
.model-4665 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4665.png");
}
.model-4666 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4666.png");
}
.model-4667 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_opac/model-4667.png");
}

.duo-model-gri-0705 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/gri-0705.png");
  background-size: 50px 50px;
}

.duo-model-alb-1025 {
  background-color: #8e8e8b;
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/alb-1025.png");
  background-size: 50px 50px;
}

.duo-model-bej-1085 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/bej-1085.png");
  background-size: 50px 50px;
}

.duo-model-albastru-1100 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/albastru-1100.png");
  background-size: 50px 50px;
}

.duo-model-gri-deschis-1705 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/gri-deschis-1705.png");
  background-size: 50px 50px;
}

.duo-model-albastru-2055 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/albastru-2055.png");
  background-size: 50px 50px;
}
.duo-model-negru-3009 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/negru-3009.png");
  background-size: 50px 50px;
}

.duo-model-albastru-pal-4555 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/albastru-pal-4555.png");
  background-size: 50px 50px;
}

.duo-model-bej-4556 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/bej-4556.png");
  background-size: 50px 50px;
}

.duo-model-esential-4558 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/model-esential-4558.png");
  background-size: 50px 50px;
}
.duo-model-rosu-inchis-4560 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/rosu-inchis-4560.jpg");
  background-size: 50px 50px;
}
.duo-model-purpuriu-inchis-4561 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/purpuriu-inchis-4561.png");
  background-size: 50px 50px;
}
.duo-model-intunecat-4562 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/model-intunecat-4562.png");
  background-size: 50px 50px;
}
.duo-model-curry-4563 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/curry-4563.png");
  background-size: 50px 50px;
}
.duo-model-portocaliu-4564 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/portocaliu-4564.png");
  background-size: 50px 50px;
}
.duo-model-roz-pal-4565 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/roz-pal-4565.png");
  background-size: 50px 50px;
}
.duo-model-verde-masliniu-4567 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/verde-masliniu-4567.png");
  background-size: 50px 50px;
}
.duo-model-vegetal-4568 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/model-vegetal-4568.png");
  background-size: 50px 50px;
}
.duo-model-verde-pal-4569 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/verde-pal-4569.jpg");
  background-size: 50px 50px;
}
.duo-model-galben-aprins-4570 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/galben-aprins-4570.jpg");
  background-size: 50px 50px;
}

.duo-model-albastru-deschis-4571 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/albastru-deschis-4571.jpg");
  background-size: 50px 50px;
}

.duo-model-rosu-aprins-4572 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/rosu-aprins-4572.jpg");
  background-size: 50px 50px;
}

.duo-model-grafic-4573 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/model-grafic-4573.jpg");
  background-size: 50px 50px;
}

.duo-model-maro-inchis-4559 {
  background-image: url("/assets/images/ferestre_velux/rulouri/control_lumina/rulou_duo/maro-inchis-4559.png");
  background-size: 50px 50px;
}

.alb-1028 {
  background-color: #edece8;
}

.bej-1086 {
  background-color: #fae5ca;
}

.albastru-1952 {
  background-color: #174f80;
}

.granulat_natural_4000 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/granulat_natural_4000.jpg");
  background-size: 50px 50px;
}

.maro_inchis_4060 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/maro_inchis_4060.jpg");
  background-size: 50px 50px;
}

.negru_4069 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/negru_4069.jpg");
  background-size: 50px 50px;
}

.galben_aprins_4073 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/galben_aprins_4073.jpg");
  background-size: 50px 50px;
}

.verde_masliniu_4079 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/verde_masliniu_4079.jpg");
  background-size: 50px 50px;
}

.nisip_4155 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/nisip_4155.jpg");
  background-size: 50px 50px;
}

.model-minimalist_4156 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/model_minimalist_4156.jpg");
  background-size: 50px 50px;
}

.purpuriu_inchis_4157 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/purpuriu_inchis_4157.jpg");
  background-size: 50px 50px;
}

.model_romantic_4158 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/model_romantic_4158.jpg");
  background-size: 50px 50px;
}

.rosu_aprins_4159 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/rosu_aprins_4159.jpg");
  background-size: 50px 50px;
}

.model_constructivist_4160 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/model_constructivist_4160.jpg");
  background-size: 50px 50px;
}

._9050 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/9050.jpg");
  background-size: 50px 50px;
}

.alb-1016 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/alb_1016.jpg");
  background-size: 50px 50px;
}

.classic-sand-1259 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/classic_sand_1259.jpg");
  background-size: 50px 50px;
}

.albastru-metalic-1265 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/rulou_decorativ/albastru_metalic_1265.jpg");
  background-size: 50px 50px;
}

.jaluzea-square {
  width: 95px;
  height: 70px;
}

._6500 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6500.jpg");
  background-size: 95px 70px;
}

._6501 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6501.jpg");
  background-size: 95px 70px;
}

._6502 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6502.jpg");
  background-size: 95px 70px;
}

._6503 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6503.jpg");
  background-size: 95px 70px;
}

._6504 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6504.jpg");
  background-size: 95px 70px;
}

._6505 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6505.jpg");
  background-size: 95px 70px;
}

._6506 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6506.jpg");
  background-size: 95px 70px;
}

._6507 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6507.jpg");
  background-size: 95px 70px;
}

._6508 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6508.jpg");
  background-size: 95px 70px;
}

._6509 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6509.jpg");
  background-size: 95px 70px;
}

._6510 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6510.jpg");
  background-size: 95px 70px;
}

._6511 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6511.jpg");
  background-size: 95px 70px;
}

._6512 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6512.jpg");
  background-size: 95px 70px;
}

._6513 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6513.jpg");
  background-size: 95px 70px;
}

._6514 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6514.jpg");
  background-size: 95px 70px;
}

._6515 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6515.jpg");
  background-size: 95px 70px;
}

._6516 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6516.jpg");
  background-size: 95px 70px;
}

._6517 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6517.jpg");
  background-size: 95px 70px;
}

._6518 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6518.jpg");
  background-size: 95px 70px;
}
._6519 {
  background-image: url("/assets/images/ferestre_velux/rulouri/filtrare_lumina/jaluzea_romana/6519.jpg");
  background-size: 95px 70px;
}
