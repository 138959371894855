.natural {
  background-color: #b6623f;
}

.text-natural {
  color: #b6623f;
}

.rosu-natural {
  background-color: #985b3f;
}

.text-rosu-natural-engobat {
  color: #985b3f;
}

.rosu-rustic-engobat {
  background: rgb(206, 124, 77);
  background: linear-gradient(90deg, rgba(206, 124, 77, 1) 0%, rgba(92, 43, 26, 1) 100%);
}

.text-rosu-rustic-engobat {
  background: rgb(206, 124, 77);
  background: linear-gradient(90deg, rgba(206, 124, 77, 1) 0%, rgba(92, 43, 26, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bordeaux-engobat {
  background-color: #914d42;
}

.text-bordeaux-engobat {
  color: #914d42;
}

.maro-engobat {
  background-color: #5a4333;
}

.text-maro-engobat {
  color: #5a4333;
}

.invechit-engobat {
  background-color: #524743;
}

.text-invechit-engobat {
  color: #524743;
}

.negru-invechit-engobat {
  background-color: #494440;
}

.text-negru-invechit-engobat {
  color: #494440;
}

.antracit-engobat {
  background-color: #616669;
}

.text-antracit-engobat {
  color: #616669;
}

.negru-mat-engobat {
  background-color: #161c18;
}

.text-negru-mat-engobat {
  color: #161c18;
}

.migdala-glazurat {
  background-color: #813623;
}

.text-migdala-glazurat {
  color: #813623;
}

.negru-glazurat-engobat {
  background-color: #272c28;
}

.text-negru-glazurat-engobat {
  color: #272c28;
}

.verde-brad-glazurat {
  background-color: #052c19;
}

.text-verde-brad-glazurat {
  color: #052c19;
}

.metalic-glazurat {
  background-color: #635f5c;
}

.text-metalic-glazurat {
  color: #635f5c;
}

.negru-cobalt-glazurat {
  background-color: #242c2f;
}

.text-negru-cobalt-glazurat {
  color: #242c2f;
}

.ars-natural {
  background-color: #424240;
}

.text-ars-natural {
  color: #424240;
}

.rosu-engobat {
  background-color: #b67754;
}

.text-rosu-engobat {
  color: #b67754;
}

.afumat-in-masa {
  background-color: #77726f;
}

.text-afumat-in-masa {
  color: #77726f;
}

.maro-inchis-engobat {
  background-color: #674730;
}

.text-maro-inchis-engobat {
  color: #674730;
}

.rosu-cupru-engobat {
  background-color: #89412b;
}

.text-rosu-cupru-engobat {
  color: #89412b;
}

.invechit-rustic {
  background: rgb(110, 90, 81);
  background: linear-gradient(90deg, rgba(110, 90, 81, 1) 45%, rgba(83, 84, 79, 1) 100%);
}

.text-invechit-rustic {
  background: rgb(110, 90, 81);
  background: linear-gradient(90deg, rgba(110, 90, 81, 1) 45%, rgba(83, 84, 79, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.negru-ardezie-engobat {
  background-color: #2e2f29;
}

.text-adrezie-engobat {
  color: #2e2f29;
}

.rosu-antic {
  background-color: #894633;
}

.text-rosu-antic {
  color: #894633;
}

.gri-inchis-engobat {
  background-color: #717277;
}

.text-gri-inchis-engobat {
  color: #717277;
}

.gri-zinc-glazurat {
  background-color: #8e8e8e;
}

.text-gri-zinc-glazurat {
  color: #8e8e8e;
}

.mediteran-engobat {
  background: rgb(223, 193, 121);
  background: linear-gradient(90deg, rgba(223, 193, 121, 1) 45%, rgba(165, 85, 58, 1) 100%);
}

.text-mediteran-engobat {
  background: rgb(223, 193, 121);
  background: linear-gradient(90deg, rgba(223, 193, 121, 1) 45%, rgba(165, 85, 58, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.natural-ars {
  background: rgb(116, 69, 51);
  background: linear-gradient(90deg, rgba(116, 69, 51, 1) 45%, rgba(108, 104, 103, 1) 100%);
}

.text-natural-ars {
  background: rgb(116, 69, 51);
  background: linear-gradient(90deg, rgba(116, 69, 51, 1) 45%, rgba(108, 104, 103, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.negru-grafit-ars {
  background-color: #2a2623;
}

.text-negru-grafit-ars {
  color: #2a2623;
}
