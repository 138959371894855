.antracit-briliant {
  background-color: #252527;
}

.text-antracit-briliant {
  color: #252527;
}

.antic {
  background: rgb(151, 100, 81);
  background: linear-gradient(90deg, rgba(151, 100, 81, 1) 45%, rgba(92, 72, 63, 1) 100%);
}

.text-antic {
  background: rgb(151, 100, 81);
  background: linear-gradient(90deg, rgba(151, 100, 81, 1) 45%, rgba(92, 72, 63, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rosu-caramiziu {
  background-color: #c16951;
}

.text-rosu-caramiziu {
  color: #c16951;
}

.maro {
  background-color: #754e42;
}

.text-maro {
  color: #754e42;
}

.magico {
  background: rgb(178, 107, 85);
  background: linear-gradient(90deg, rgba(178, 107, 85, 1) 45%, rgba(116, 86, 76, 1) 100%);
}

.text-magico {
  background: rgb(178, 107, 85);
  background: linear-gradient(90deg, rgba(178, 107, 85, 1) 45%, rgba(116, 86, 76, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.umbra {
  background: rgb(160, 137, 121);
  background: linear-gradient(90deg, rgba(160, 137, 121, 1) 45%, rgba(133, 95, 74, 1) 100%);
}

.text-umbra {
  background: rgb(160, 137, 121);
  background: linear-gradient(90deg, rgba(160, 137, 121, 1) 45%, rgba(133, 95, 74, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.montano {
  background: rgb(144, 73, 45);
  background: linear-gradient(90deg, rgba(144, 73, 45, 1) 45%, rgba(93, 47, 32, 1) 100%);
}

.text-montano {
  background: rgb(144, 73, 45);
  background: linear-gradient(90deg, rgba(144, 73, 45, 1) 45%, rgba(93, 47, 32, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.brun-roscat-inchis {
  background-color: #843c30;
}

.text-brun-roscat-inchis {
  color: #843c30;
}
