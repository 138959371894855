html,
body {
  font-family: "Segoe UI", Arial, sans-serif !important;
}
#root {
  color: #1b1b1f;
  font-weight: 400;
}
.App {
  background-image: url(bg.png);
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  height: 100%;
  margin: auto;
  background-color: white;
  padding: 0px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    2px 10px 30px 0 rgba(0, 0, 0, 0.19);
  margin-top: 8px !important;
  width: 90%;
  max-width: 1713px;
}

.body-section {
  min-height: 560.5px;
  padding: 16px;
}

body.short-content .footer-section {
  position: absolute;
  bottom: 0;
}

.col {
  margin-top: 8px !important;
}
