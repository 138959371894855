.carousel {
  margin: auto !important;
  margin-top: 16px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 16px !important;
}

.legend {
  background-color: transparent !important;
  font-weight: 900;
  font-size: 2.5vh !important;
  text-transform: uppercase;
  bottom: 11px !important;
  text-shadow: 0px 0.5px 0.5px #b13336, 0px 0.5px 0.5px #b13336;
  text-align: center;
}
