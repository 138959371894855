.header-container {
  max-width: 1280px !important;
}
.logo-header {
  background-color: #fefefe;
  width: 100%;
  border-radius: 8px;
  text-align: center;
}

.logo {
  max-width: 340px;
  width: 100%;
  height: 120.5px;
  margin: 0;
}

.navbar {
  background-color: #fefefe !important;
  z-index: 1000;
}

.navbar-toggler {
  border-color: #1b1b1f !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(27, 27, 31, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navi {
  font-weight: 420 !important;
  font-size: 16px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
#basic-navbar-nav {
  transition: all 0.4s !important;
}

.ml-auto {
  background-color: transparent !important;
  border-radius: 4px;
}

.nav-link {
  color: #1b1b1f !important;
  padding: 16px !important;
  min-width: 110px;
  text-align: center;
  font-weight: 500;
  font-family: "Segoe UI", Arial, sans-serif !important;
}

.nav-link:hover {
  transition: all 0.5s !important;
  color: #fefefe !important;
  background-color: #b13336;
  border-radius: 8px;
}

.navbar-collapse {
}

.dropdown-menu {
  -webkit-animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.dropdown-item {
  padding: 16px !important;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-3-11 15:19:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
