.creaton-container {
  width: 100%;
  margin: auto;
  text-align: center;
}

.date-tehnice {
  list-style-type: none;
}

.text-title {
  font-weight: bold;
}

.min-width {
  min-width: 240px;
}

.text-creaton {
  text-indent: 0px !important;
}

.tigla-imagine {
  margin: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 2px 8px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-top: 14px;
}

.tigla-imagine img {
  width: 100%;
}

.dot {
  margin-top: 4px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  margin-left: 4px;
  transition: 0.2s;
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.dots {
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 64px;
}

.dot:hover {
  -moz-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  -o-transform: translate(0px, -4px);
  -webkit-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
}

.text-culoare {
  font-weight: bold;
  padding-top: 8px;
}

.ardezie-angoba {
  color: #454f50;
}

.rosu-natur {
  background-color: #ce8050;
}

.text-rosu-natur {
  color: #ce8050;
}

.rosu-angoba {
  background-color: #cd683e;
}

.text-rosu-angoba {
  color: #cd683e;
}
.cupru-angoba {
  background-color: #6b3022;
}

.text-cupru-angoba {
  color: #6b3022;
}
.rosu-vin-angoba {
  background-color: #631c16;
}

.text-rosu-vin-angoba {
  color: #631c16;
}

.maro-inchis-angoba {
  background-color: #3d2e29;
}

.text-maro-inchis {
  color: #3d2e29;
}
.adrezie-angoba {
  background-color: #485a66;
}

.text-ardezie-angoba {
  color: #485a66;
}

.negru-angoba {
  background-color: #24282b;
}

.text-negru-angoba {
  color: #24282b;
}
.negru-mat-angoba {
  background-color: #2e2f31;
}

.text-negru-mat-angoba {
  color: #2f3437;
}

.rosu-cupru {
  background-color: #a54f34;
}

.text-rosu-cupru {
  color: #a54f34;
}

.rosu-vin {
  background-color: #913422;
}

.text-rosu-vin {
  color: #913422;
}

.gri {
  background-color: #616e76;
}

.text-gri-angoba {
  color: #616e76;
}

.gri-inchis-angoba {
  background-color: #473531;
}

.text-gri-inchis-angoba {
  color: #473531;
}
.ardezie {
  background-color: #454f50;
}

.text-ardezie {
  color: #454f50;
}

.negru {
  background-color: #2f3437;
}

.text-negru {
  color: #2f3437;
}

.ardezie-smaltuit {
  background-color: #2f3a40;
}
.text-ardezie-smaltuit {
  color: #2f3a40;
}

.rosu-vin-smaltuit {
  background-color: #66171a;
}

.text-rosu-vin-smaltuit {
  color: #66171a;
}
.rosu-smaltuit {
  background-color: #c76c3f;
}
.text-rosu-smaltuit {
  color: #c76c3f;
}

.negru-smaltuit {
  background-color: #162424;
}

.text-negru-smaltuit {
  color: #162424;
}

.maro-smaltuit {
  background-color: #ad4333;
}

.text-maro-smaltuit {
  color: #ad4333;
}

.albastru-inchis {
  background-color: #05294b;
}

.text-albastru-inchis {
  color: #05294b;
}

.verde-inchis {
  background-color: #002420;
}

.text-verde-inchis {
  color: #002420;
}

.noblesse-maro-smaltuit {
  background-color: #251b11;
}

.text-noblesse-maro-smaltuit {
  color: #251b11;
}

.maro {
  background-color: #814932;
}

.text-maro {
  color: #814932;
}

.mangan {
  background-color: #6b5847;
}

.text-mangan {
  color: #6b5847;
}

.ardezie-cristal {
  background-color: #424848;
}

.text-ardezie-cristal {
  color: #424848;
}

.negru-cristal {
  background-color: #141615;
}

.text-negru-cristal {
  color: #141615;
}

.rosu-natur-ardere {
  background: rgb(182, 95, 65);
  background: linear-gradient(90deg, rgba(182, 95, 65, 1) 0%, rgba(87, 66, 61, 1) 94%);
}

.text-rosu-natur-ardere {
  background: rgb(182, 95, 65);
  background: linear-gradient(90deg, rgba(182, 95, 65, 1) 0%, rgba(87, 66, 61, 1) 94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* ------------------

----------------------------
 * Generated by Animista on 2020-3-13 15:16:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 
@media screen and (min-width: 1769px) and (max-width: 2200px) {
  .creaton-container {
    min-height: 644px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1025px) {
  .creaton-container {
    min-height: 300px !important;
  }
} */
