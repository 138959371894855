.homepage {
  -webkit-animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.main-text {
  text-indent: 24px;
  text-align: justify;
  padding-top: 8px;
}

.politica-title {
  font-size: 1.4em !important;
  font-weight: bold;
}

.politica h4 {
  font-size: 1.3em;
}

.politica h5 {
  font-size: 1.1em;
}

.products-section {
  margin-top: 16px;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-11 13:20:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact-button {
  background-color: #006cbf !important;
}

.margin-top {
  margin-top: 16px;
}
