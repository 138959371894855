.wetterbest-col {
  min-width: 256px;
}

.wetterbest-culori {
  width: 80%;
  margin: auto;
  min-width: 256px;
}

.wetterbest-square {
  width: 40px;
  height: 40px;
  margin-left: 8px;
  margin-top: 8px;
  cursor: pointer;
}

.ral7016 {
  background-color: #383e42;
}

.ral8019 {
  background-color: #302920;
}

.ral6020 {
  background-color: #414c31;
}

.ral1015 {
  background-color: #d3b785;
}

.ral3000 {
  background-color: #8a1b1b;
}

.ral3005 {
  background-color: #59191f;
}

.ral3009 {
  background-color: #5c0e0c;
}

.ral3011 {
  background-color: #420b0e;
}

.ral5010 {
  background-color: #1f496d;
}

.ral6005 {
  background-color: #31523b;
}

.ral6011 {
  background-color: #6c7c59;
}

.ral7024 {
  background-color: #878581;
}

.ral8004 {
  background-color: #6f3e2e;
}

.ral8017 {
  background-color: #3a1d17;
}

.ral9002 {
  background-color: #b3b3b3;
}

.ral9006 {
  background-color: #818181;
}

.ral9007 {
  background-color: #878581;
}

.ral3009 {
  background-color: #5c0e0c;
}

.ral7024 {
  background-color: #394042;
}

.ral8017 {
  background-color: #3a1d17;
}

.ral9005 {
  background-color: #2a2a2b;
}
